import React from 'react'

const HalfCircle = () => {
    return (
        <svg className='half_circle' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232 207" fill="none">
            <path d="M100 199C80.4197 199 61.279 193.194 44.9985 182.315C28.7181 171.437 16.029 155.976 8.53593 137.886C1.04285 119.796 -0.91768 99.8902 2.90226 80.6861C6.72219 61.4819 16.151 43.8418 29.9964 29.9964C43.8418 16.151 61.4819 6.72219 80.6861 2.90226C99.8902 -0.91768 119.796 1.04285 137.886 8.53593C155.976 16.029 171.437 28.7181 182.315 44.9985C193.194 61.279 199 80.4196 199 100" stroke="#4D4D4D" stroke-width="2" />
            <path d="M231.707 198.293C232.098 198.683 232.098 199.317 231.707 199.707L225.343 206.071C224.953 206.462 224.319 206.462 223.929 206.071C223.538 205.681 223.538 205.047 223.929 204.657L229.586 199L223.929 193.343C223.538 192.953 223.538 192.319 223.929 191.929C224.319 191.538 224.953 191.538 225.343 191.929L231.707 198.293ZM100 198H231V200H100V198Z" fill="#4D4D4D" />
        </svg>
    )
}

export default HalfCircle