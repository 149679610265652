import React from 'react'

const IT = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.3516 38.2129V13.457C55.3516 12.4469 54.9504 11.4782 54.2361 10.7639C53.5219 10.0497 52.553 9.64844 51.543 9.64844H13.457C12.4469 9.64844 11.4782 10.0497 10.7639 10.7639C10.0497 11.4782 9.64844 12.4469 9.64844 13.457V38.2129H55.3516Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M60.7367 49.9967C60.9944 50.5766 61.1031 51.2116 61.0528 51.8441C61.0028 52.4768 60.7953 53.0867 60.4495 53.6187C60.1037 54.1508 59.6301 54.5881 59.0726 54.8905C58.5147 55.1931 57.8904 55.3516 57.2556 55.3516H7.74402C7.10946 55.3516 6.48495 55.1931 5.92722 54.8905C5.36949 54.5881 4.89619 54.1508 4.55035 53.6187C4.2045 53.0867 3.99706 52.4768 3.94687 51.8441C3.89668 51.2116 4.00534 50.5766 4.26296 49.9967L9.64832 38.2129H55.3513L60.7367 49.9967Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.6914 47.7344H36.3086" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default IT