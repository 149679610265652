import React from 'react'

const Payroll = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0826 34.5069H16.4303C16.6038 34.5003 16.7769 34.5305 16.9379 34.5955C17.0989 34.6607 17.2442 34.7593 17.3642 34.8849C17.4842 35.0104 17.5762 35.1599 17.6341 35.3237C17.6919 35.4875 17.7144 35.6616 17.6998 35.8348V59.7248C17.7144 59.898 17.6919 60.0722 17.6341 60.2359C17.5762 60.3997 17.4842 60.5493 17.3642 60.6747C17.2442 60.8004 17.0989 60.8989 16.9379 60.9642C16.7769 61.0292 16.6038 61.0594 16.4303 61.0528H10.0826" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.2394 28.9047L23.3594 34.9806C23.0389 35.38 22.6359 35.7055 22.1779 35.9346C21.72 36.1638 21.2179 36.2913 20.7061 36.3086H17.6998" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.6998 54.1579C23.1436 58.2839 28.1099 61.0538 31.3168 61.0538H47.2443C49.174 61.0538 50.3877 60.9167 51.2256 58.4005C52.505 51.9774 53.3919 45.4825 53.8814 38.9512C53.8814 37.6258 52.5535 36.2979 49.9002 36.2979H34.8384" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.8282 33.0205L24.8979 5.51738C24.8699 5.32091 24.8844 5.12071 24.9405 4.93033C24.9965 4.73995 25.0928 4.56383 25.2228 4.41386C25.3528 4.2639 25.5134 4.14359 25.6939 4.06107C25.8744 3.97855 26.0705 3.93574 26.269 3.93555H45.4489" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.1693 36.3088L34.8435 13.0536C34.8245 12.8759 34.8433 12.6963 34.8986 12.5264C34.9537 12.3565 35.0444 12.2001 35.1642 12.0676C35.284 11.935 35.4305 11.8293 35.5941 11.7573C35.7576 11.6852 35.9343 11.6485 36.113 11.6495H53.6326C53.8172 11.6473 53.9997 11.6854 54.1681 11.761C54.3364 11.8367 54.4862 11.9481 54.6071 12.0875C54.7279 12.227 54.817 12.3911 54.8678 12.5685C54.9189 12.7459 54.9305 12.9322 54.9021 13.1145L51.3982 36.4739" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M44.4235 25.8244C43.8976 25.8244 43.4713 25.3981 43.4713 24.8723C43.4713 24.3464 43.8976 23.9201 44.4235 23.9201" stroke="#B00819" stroke-width="2" />
            <path d="M44.4235 25.8244C44.9496 25.8244 45.3756 25.3981 45.3756 24.8723C45.3756 24.3464 44.9496 23.9201 44.4235 23.9201" stroke="#B00819" stroke-width="2" />
        </svg>

    )
}

export default Payroll