import React from 'react'

const Accounting = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8317 25.1509H10.3458C8.79124 25.1509 7.53186 26.4103 7.53186 27.9649V54.7381C7.53186 56.2927 8.79124 57.5521 10.3458 57.5521H54.6542C56.2087 57.5521 57.4681 56.2927 57.4681 54.7381V27.9649C57.4681 26.4103 56.2087 25.1509 54.6542 25.1509H47.4987" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M45.6666 48.4291H19.6639C19.349 48.4291 19.0938 48.6843 19.0938 48.9992V52.3643C19.0938 52.6791 19.349 52.9344 19.6639 52.9344H45.6666C45.9814 52.9344 46.2367 52.6791 46.2367 52.3643V48.9992C46.2367 48.6843 45.9814 48.4291 45.6666 48.4291Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.5223 40.3271H15.1043C14.7895 40.3271 14.5342 40.5823 14.5342 40.8972V44.3151C14.5342 44.63 14.7895 44.8852 15.1043 44.8852H18.5223C18.8371 44.8852 19.0924 44.63 19.0924 44.3151V40.8972C19.0924 40.5823 18.8371 40.3271 18.5223 40.3271Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.4482 40.3271H23.0303C22.7154 40.3271 22.4602 40.5823 22.4602 40.8972V44.3151C22.4602 44.63 22.7154 44.8852 23.0303 44.8852H26.4482C26.7631 44.8852 27.0183 44.63 27.0183 44.3151V40.8972C27.0183 40.5823 26.7631 40.3271 26.4482 40.3271Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M34.9443 41.1058V44.3151C34.9445 44.39 34.9299 44.4641 34.9014 44.5332C34.8729 44.6024 34.831 44.6652 34.7782 44.7182C34.7253 44.7712 34.6626 44.8132 34.5935 44.8418C34.5244 44.8705 34.4503 44.8853 34.3755 44.8853H30.9563C30.8054 44.8853 30.6608 44.8253 30.5541 44.7187C30.4474 44.612 30.3875 44.4673 30.3875 44.3165V41.1071" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.2988 40.3271H38.8808C38.566 40.3271 38.3107 40.5823 38.3107 40.8972V44.3151C38.3107 44.63 38.566 44.8852 38.8808 44.8852H42.2988C42.6136 44.8852 42.8689 44.63 42.8689 44.3151V40.8972C42.8689 40.5823 42.6136 40.3271 42.2988 40.3271Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M50.2247 40.3271H46.8067C46.4919 40.3271 46.2366 40.5823 46.2366 40.8972V44.3151C46.2366 44.63 46.4919 44.8852 46.8067 44.8852H50.2247C50.5395 44.8852 50.7948 44.63 50.7948 44.3151V40.8972C50.7948 40.5823 50.5395 40.3271 50.2247 40.3271Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.5223 32.2251H15.1043C14.7895 32.2251 14.5342 32.4803 14.5342 32.7952V36.2131C14.5342 36.528 14.7895 36.7832 15.1043 36.7832H18.5223C18.8371 36.7832 19.0924 36.528 19.0924 36.2131V32.7952C19.0924 32.4803 18.8371 32.2251 18.5223 32.2251Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.7487 36.7833H23.029C22.9542 36.7833 22.8801 36.7685 22.811 36.7398C22.7419 36.7112 22.6791 36.6692 22.6263 36.6162C22.5735 36.5632 22.5316 36.5004 22.5031 36.4312C22.4746 36.3621 22.46 36.288 22.4602 36.2132V35.0283M42.8702 35.0283V36.2132C42.8702 36.288 42.8555 36.362 42.8268 36.4311C42.7981 36.5002 42.7561 36.563 42.7031 36.6158C42.6502 36.6686 42.5873 36.7105 42.5182 36.739C42.449 36.7675 42.3749 36.7821 42.3001 36.7819H40.5817" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M50.2247 32.2251H46.8067C46.4919 32.2251 46.2366 32.4803 46.2366 32.7952V36.2131C46.2366 36.528 46.4919 36.7832 46.8067 36.7832H50.2247C50.5395 36.7832 50.7948 36.528 50.7948 36.2131V32.7952C50.7948 32.4803 50.5395 32.2251 50.2247 32.2251Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M46.3843 32.3348L32.6652 42.854L18.9475 32.3348C18.6001 32.0691 18.3187 31.7268 18.1252 31.3345C17.9317 30.9423 17.8312 30.5107 17.8317 30.0734V9.727C17.8317 8.46762 18.8514 7.44794 20.1107 7.44794H45.2197C46.4791 7.44794 47.4987 8.46898 47.4987 9.727V30.0734C47.4987 30.959 47.0884 31.7945 46.3843 32.3348Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.0147 15.9237L26.4158 13.5268V31.131M42.9853 25.2281V25.2999C42.9853 26.8464 42.371 28.3296 41.2775 29.4231C40.1839 30.5166 38.7008 31.131 37.1543 31.131C36.3884 31.1311 35.63 30.9804 34.9224 30.6875C34.2148 30.3945 33.5719 29.965 33.0302 29.4236C32.4886 28.8821 32.059 28.2392 31.7659 27.5317C31.4728 26.8241 31.3219 26.0658 31.3219 25.2999V19.3592C31.3217 18.5932 31.4725 17.8347 31.7655 17.127C32.0585 16.4193 32.4881 15.7763 33.0298 15.2347C33.5714 14.693 34.2144 14.2634 34.9221 13.9704C35.6298 13.6774 36.3883 13.5266 37.1543 13.5268C37.9201 13.5268 38.6785 13.6777 39.386 13.9708C40.0936 14.2639 40.7365 14.6935 41.2779 15.2351C41.8194 15.7768 42.2489 16.4197 42.5419 17.1273C42.8348 17.8349 42.9855 18.5933 42.9853 19.3592V19.431" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Accounting