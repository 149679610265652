import React from 'react'

const Fitness = () => {
    return (
        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.9735 28.8609L43.9639 27.8656C44.4615 27.6171 45.2077 27.8656 45.7054 28.3633L48.442 33.8367C49.1885 35.0806 50.4321 35.8269 51.6763 35.8269C52.1739 35.8269 52.9201 35.5783 53.4175 35.3295C55.1591 34.3341 55.9056 32.0952 55.1591 30.3537L52.4225 24.8802C51.4274 22.8899 49.6859 21.1485 47.4467 20.4023C45.2077 19.6558 42.9688 19.9047 40.7296 20.8999L35.2564 23.6364L28.5393 27.1195C28.0416 27.3681 27.544 27.3682 27.0465 26.8706L23.3147 23.1389C21.822 21.6461 19.5828 21.6461 18.0901 23.1389C16.5976 24.6316 16.5976 26.8706 18.0901 28.3633L21.822 32.0952C24.5586 34.8318 28.5393 35.3295 31.7733 33.8367L35.2564 32.0952L37.993 37.0707L30.7782 41.7977C29.2855 42.7928 28.2902 44.0367 27.544 45.5294L24.3099 54.4859C23.5634 56.476 24.3099 58.4664 26.3 59.4615C26.7976 59.7105 27.2952 59.7106 27.7928 59.7106C29.2855 59.7106 30.5292 58.9641 31.2756 57.4714L34.5099 48.5151C34.5099 48.2662 34.7587 48.0174 35.0076 48.0174L40.9784 44.0369L41.4761 45.5294C42.72 49.0125 45.9542 51.2517 49.6859 51.2517C50.4321 51.2517 51.1786 51.2517 51.9248 51.0031L57.6469 49.5104C59.6372 49.0127 60.8814 47.0223 60.3837 45.032C59.8861 43.0419 57.8959 41.798 55.9056 42.2954L50.1835 43.7883C49.6859 44.0369 48.9394 43.539 48.6908 43.0416L48.1932 41.5489" stroke="#B00819" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.6317 19.9686C35.7538 19.9686 39.0952 16.6271 39.0952 12.5049C39.0952 8.38299 35.7538 5.0415 31.6317 5.0415C27.5098 5.0415 24.1683 8.38299 24.1683 12.5049C24.1683 16.6271 27.5098 19.9686 31.6317 19.9686Z" stroke="#B00819" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.1873 5.0415C16.0654 5.0415 12.7239 8.38299 12.7239 12.5049C12.7239 14.9607 13.9098 17.1395 15.7403 18.4996" stroke="#B00819" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.9387 41.0508L18.446 42.0458C16.9532 43.0412 15.9581 44.2848 15.2118 45.7775L11.9774 54.734C11.2312 56.7244 11.9775 58.7145 13.9678 59.7098C14.4653 59.9584 14.9629 59.9584 15.4605 59.9584C16.207 59.9584 16.9532 59.7098 17.4508 59.2122" stroke="#B00819" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8507 23.1386C9.35792 21.6459 7.11893 21.6459 5.62621 23.1386C4.13347 24.6313 4.13347 26.8703 5.62621 28.363L9.35792 32.0949C10.8507 33.5879 13.0899 34.5829 15.08 34.5829" stroke="#B00819" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Fitness