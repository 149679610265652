import React from 'react'

const Education = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.3516 61.0645H15.3613C13.8462 61.0645 12.3931 60.4624 11.3217 59.3912C10.2503 58.3197 9.64844 56.8666 9.64844 55.3516" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.5 7.74414H17.2656C15.2454 7.74414 13.308 8.54666 11.8795 9.97516C10.451 11.4037 9.64844 13.3411 9.64844 15.3613V55.3516C9.64844 53.8365 10.2503 52.3834 11.3217 51.3119C12.3931 50.2407 13.8462 49.6387 15.3613 49.6387H53.4473C53.9523 49.6387 54.4367 49.4381 54.7937 49.0808C55.151 48.7238 55.3516 48.2394 55.3516 47.7344V9.64844C55.3516 9.14339 55.151 8.65902 54.7937 8.3019C54.4367 7.94478 53.9523 7.74414 53.4473 7.74414H47.7344" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M51.543 61.0645V49.6387" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M47.7344 34.4043L40.1172 26.7871L32.5 34.4043V7.74414C32.5 6.73405 32.9012 5.7653 33.6154 5.05106C34.3296 4.33681 35.2986 3.93555 36.3086 3.93555H43.9258C44.9358 3.93555 45.9047 4.33681 46.619 5.05106C47.3332 5.7653 47.7344 6.73405 47.7344 7.74414V34.4043Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Education