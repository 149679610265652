import React from 'react'

const Calculation = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.882 41.7346L13.457 43.0803L14.8027 33.6553L39.0406 9.41741C39.571 8.88692 40.201 8.46612 40.8941 8.17901C41.587 7.89191 42.33 7.74414 43.0803 7.74414C43.8306 7.74414 44.5735 7.89191 45.2664 8.17901C45.9596 8.46612 46.5895 8.88692 47.1199 9.41741C47.6503 9.94792 48.0713 10.5777 48.3582 11.2708C48.6454 11.964 48.7932 12.7068 48.7932 13.4571C48.7932 14.2073 48.6454 14.9502 48.3582 15.6433C48.0713 16.3364 47.6503 16.9662 47.1199 17.4967L22.882 41.7346Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.3613 22.9785H7.74414C6.73405 22.9785 5.7653 23.3798 5.05106 24.094C4.33681 24.8083 3.93555 25.777 3.93555 26.7871V53.4473C3.93555 54.4573 4.33681 55.4262 5.05106 56.1404C5.7653 56.8547 6.73405 57.2559 7.74414 57.2559H57.2559C58.2659 57.2559 59.2348 56.8547 59.949 56.1404C60.6633 55.4262 61.0645 54.4573 61.0645 53.4473V26.7871C61.0645 25.777 60.6633 24.8083 59.949 24.094C59.2348 23.3798 58.2659 22.9785 57.2559 22.9785H53.4473" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M53.4473 47.7344H34.4043" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M53.4473 38.2129H42.0215" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Calculation