import React from 'react'

const Services = () => {
    return (

        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.398 4.61296C31.5009 4.4089 31.6583 4.23741 31.853 4.11759C32.0475 3.99777 32.2715 3.93433 32.5 3.93433C32.7285 3.93433 32.9525 3.99777 33.1472 4.11759C33.3417 4.23741 33.4991 4.4089 33.6019 4.61296L36.1639 9.77741C36.2525 9.95444 36.3825 10.1074 36.5429 10.2232C36.7034 10.3391 36.8898 10.4144 37.0855 10.4426L42.834 11.2729C43.0584 11.3045 43.2699 11.3979 43.4444 11.5427C43.6188 11.6876 43.7493 11.8781 43.8217 12.093C43.8925 12.3071 43.9014 12.5369 43.8468 12.7557C43.7925 12.9746 43.6772 13.1735 43.5144 13.3296L39.3555 17.3768C39.2151 17.5123 39.1097 17.68 39.0487 17.8654C38.9876 18.0508 38.9726 18.2482 39.0051 18.4407L39.9902 24.146C40.0276 24.3692 40.0009 24.5983 39.9138 24.8072C39.8267 25.016 39.6825 25.196 39.4977 25.3266C39.3115 25.4595 39.0922 25.5382 38.8642 25.5539C38.6359 25.5695 38.4079 25.5216 38.2053 25.4155L33.0738 22.7393C32.8971 22.6455 32.7001 22.5965 32.5 22.5965C32.2999 22.5965 32.1029 22.6455 31.9262 22.7393L26.8074 25.4155C26.6049 25.5216 26.3768 25.5695 26.1487 25.5539C25.9205 25.5382 25.7012 25.4595 25.5151 25.3266C25.3303 25.196 25.186 25.016 25.0989 24.8072C25.0118 24.5983 24.9853 24.3692 25.0225 24.146L26.0076 18.4407C26.0401 18.2482 26.0251 18.0508 25.9641 17.8654C25.903 17.68 25.7977 17.5123 25.6572 17.3768L21.4856 13.3296C21.3228 13.1735 21.2075 12.9746 21.1531 12.7557C21.0988 12.5369 21.1075 12.3071 21.1783 12.093C21.2506 11.8781 21.3813 11.6876 21.5557 11.5427C21.7302 11.3979 21.9415 11.3045 22.166 11.2729L27.9144 10.4426C28.1105 10.4144 28.2966 10.3391 28.457 10.2232C28.6175 10.1074 28.7475 9.95444 28.8361 9.77741L31.398 4.61296Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.0832 27.676L15.892 26.5335C15.7144 26.441 15.5171 26.3927 15.3169 26.3927C15.1166 26.3927 14.9193 26.441 14.7418 26.5335L9.62555 29.1995C9.4226 29.3053 9.19439 29.3533 8.96598 29.3381C8.73756 29.3229 8.51776 29.2452 8.33063 29.1131C8.14586 28.9821 8.00187 28.8016 7.91516 28.5921C7.82848 28.3829 7.80263 28.1534 7.84059 27.9299L8.82321 22.2273C8.85642 22.035 8.84207 21.8376 8.78144 21.6522C8.72081 21.4667 8.61572 21.2989 8.47536 21.1634L4.30114 17.1238C4.13929 16.9671 4.02485 16.7681 3.97096 16.5493C3.91707 16.3306 3.92591 16.1012 3.99645 15.8873C4.06873 15.6724 4.19941 15.4818 4.37385 15.337C4.54829 15.1921 4.75962 15.0987 4.98414 15.0671L10.73 14.2369C10.9264 14.209 11.1131 14.1339 11.2741 14.018C11.435 13.9022 11.5655 13.749 11.6543 13.5716L14.2187 8.40718C14.3212 8.20289 14.4786 8.03122 14.6733 7.91148C14.8679 7.79171 15.0921 7.72862 15.3207 7.72925" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M46.9168 27.676L49.108 26.531C49.2855 26.4385 49.4828 26.3901 49.6831 26.3901C49.8834 26.3901 50.0807 26.4385 50.2582 26.531L55.3744 29.1995C55.5773 29.3053 55.8056 29.3533 56.0341 29.3381C56.2623 29.3229 56.4822 29.2452 56.6693 29.1131C56.8542 28.9821 56.9982 28.8016 57.0847 28.5921C57.1716 28.3829 57.1975 28.1534 57.1594 27.9299L56.1768 22.2273C56.1435 22.035 56.158 21.8376 56.2187 21.6522C56.2791 21.4667 56.3842 21.2989 56.5246 21.1634L60.6988 17.1136C60.8608 16.957 60.9751 16.7579 61.0289 16.5392C61.083 16.3205 61.0741 16.091 61.0035 15.8771C60.9312 15.6622 60.8007 15.4716 60.6262 15.3268C60.4518 15.182 60.2403 15.0885 60.0158 15.057L54.2699 14.2267C54.0737 14.1988 53.887 14.1237 53.7261 14.0079C53.5651 13.892 53.4346 13.7388 53.3457 13.5615L50.7813 8.40718C50.6789 8.20289 50.5215 8.03122 50.3268 7.91148C50.132 7.79171 49.9078 7.72862 49.6793 7.72925" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.0314 41.2516V43.7907C22.1294 46.7136 21.3704 49.6013 19.8478 52.0985" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42.9711 41.2516V43.7907C42.8726 46.7134 43.6305 49.601 45.1522 52.0985" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.0594 41.8059C24.0427 41.7925 26.0002 41.3552 27.801 40.5237C29.6014 39.6919 31.2036 38.4853 32.5 36.9843C33.7962 38.4859 35.3981 39.6929 37.1988 40.525C38.9995 41.357 40.9571 41.7945 42.9406 41.8085" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.0264 40.9701C22.0264 43.7481 23.1298 46.4121 25.094 48.3763C27.0582 50.3402 29.7223 51.4437 32.5 51.4437C35.2777 51.4437 37.9417 50.3402 39.9059 48.3763C41.8702 46.4121 42.9736 43.7481 42.9736 40.9701C42.9736 38.1924 41.8702 35.5284 39.9059 33.5642C37.9417 31.5999 35.2777 30.4965 32.5 30.4965C29.7223 30.4965 27.0582 31.5999 25.094 33.5642C23.1298 35.5284 22.0264 38.1924 22.0264 40.9701Z" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M45.3146 61.0649C43.5492 59.0611 41.341 57.4963 38.8654 56.4946L32.5 60.9659L26.1346 56.5048C23.6724 57.4986 21.4737 59.0489 19.7108 61.0345" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Services