import React from 'react'

const Retail = () => {
    return (
        <svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2656 51.5432H42.4531C43.3535 51.5437 44.2251 51.2253 44.9132 50.6442C45.6011 50.0632 46.0609 49.2573 46.2109 48.3694L52.8125 9.01387C52.9651 8.10849 53.4399 7.28875 54.1491 6.70558C54.8582 6.12238 55.754 5.81501 56.6719 5.84004H59.2109" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.8714 59.1851C21.3456 59.1851 20.9193 58.7587 20.9193 58.2329C20.9193 57.7071 21.3456 57.2808 21.8714 57.2808" stroke="#B00819" stroke-width="2" />
            <path d="M21.8714 59.1851C22.3973 59.1851 22.8235 58.7587 22.8235 58.2329C22.8235 57.7071 22.3973 57.2808 21.8714 57.2808" stroke="#B00819" stroke-width="2" />
            <path d="M41.0942 59.1851C40.5684 59.1851 40.1421 58.7587 40.1421 58.2329C40.1421 57.7071 40.5684 57.2808 41.0942 57.2808" stroke="#B00819" stroke-width="2" />
            <path d="M41.0942 59.1851C41.62 59.1851 42.0464 58.7587 42.0464 58.2329C42.0464 57.7071 41.62 57.2808 41.0942 57.2808" stroke="#B00819" stroke-width="2" />
            <path d="M47.7349 40.1172H16.9615C15.2768 40.1004 13.6453 39.5256 12.3221 38.4828C10.9989 37.4397 10.0589 35.9877 9.649 34.3535L5.89119 19.627C5.8273 19.3466 5.82603 19.0556 5.88748 18.7748C5.94892 18.4939 6.07156 18.23 6.24665 18.002C6.42406 17.7735 6.65115 17.5885 6.91072 17.4609C7.17026 17.3333 7.45548 17.2665 7.7447 17.2656H51.4927" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M47.7351 17.2664L38.2136 7.74489C37.3151 6.8549 36.1014 6.35559 34.8367 6.35559C33.572 6.35559 32.3583 6.8549 31.4597 7.74489L21.9383 17.2664" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.7168 10.5114L17.5449 7.23596C16.9464 7.05734 16.3185 6.9992 15.6974 7.06491C15.0763 7.13062 14.4744 7.31886 13.9266 7.61878C13.3787 7.91867 12.8958 8.32426 12.5058 8.81206C12.1157 9.29984 11.8263 9.86016 11.6543 10.4606L9.64844 17.2653" stroke="#B00819" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Retail